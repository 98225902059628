<template><Icone class="icone-check"/></template>

<style>
.icone-check {
  display: inline-block;
}
</style>

<script>
export default {
  name: 'IconeCheck',
  components: {
    Icone: () => import('./inline.IconeCheck.svg'),
  },
}
</script>
